.App {
    display: flex;
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .leaflet-container {
    width: 80vw;
    height: 100vh;
    z-index: 0;
  }
  .sidebarLeft{
    box-sizing: border-box;
    background: #aad3df;
    font-weight: bold;
    width: 20vw;
  }
  .sidebarRight{
    width: 80vw;
    height: 100vh;
  }
  .sidebar {
    box-sizing: border-box;
    width: 80vw;
    background: #aad3df;
  }
  
  .sidebar h2,
  .sidebar p,
  .sidebar ul {
    text-align: left;
  }
  
  .sidebar h2 {
    margin-top: 0;
  }
  
  .sidebar button {
    width: 100%;
    font-weight: bold;
    padding: .8em 1em;
  }
  .circle-css{
    height: 12px;
    width: 12px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
  }
  /* .App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .leaflet-container {
    height: 600px;
    width: 100%;
  }
  
  .leaflet-div-icon {
    background: transparent;
    border: none;
  }
   */
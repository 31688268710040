body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 
.ant-layout-header {
  height: 64px;
  padding: -1px 50px;
  line-height: 64px;
  background: #001529;
} */

.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 4px 4px 0 0;
  background: #384d62;
}
.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated{
  display:flex !important;
}
.ant-table-fixed-left{
  z-index: 0 !important;
}
img, svg, video, canvas, audio, iframe, embed, object{
  display: initial !important;
}
.ant-select-arrow {
  margin-top: 0px !important;
}
/* Table CSS */
.downtimeTable tr {
  background-color: #011931 !important;
  color: black !important;
  text-align: center !important;
}

.downtimeTable th {
  color: white !important;
  font-weight: 700 !important;
  text-align: center !important;
}

.downtimeTable th {
  background-color: #214972 !important;
  font-weight: bold !important;
}

.downtimeTable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.downtimeTable tr:nth-child(even) {
  background: #bdc0c2 !important;
}

.downtimeTable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}
/* button, [type="button"],  [type="submit"]{
  background-color:none !important;
} */

/* .ant-form-item-label > label {
  color: rgb(255, 179, 39) !important;
  font-size: 15px;
  font-weight: bold;
} */
.ant-btn-primary {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff !important;;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger {
  color: #fff;
  border-color: #ff4d4f;
  background:  #ff4d4f !important;
  text-shadow: 0 -1px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
}

.ant-switch {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-switch-checked {
  background-color: #1890ff !important;
}
img, svg, video, canvas, audio, iframe, embed, object {
  
  vertical-align: baseline !important;
}
.ant-tree-show-line .ant-tree-switcher {
  background: transparent;
}
.ant-select-selection-item{
  color: black;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color:grey;
}